// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';  
@import '~@syncfusion/ej2-calendars/styles/material.css';  
@import '~@syncfusion/ej2-dropdowns/styles/material.css';  
@import '~@syncfusion/ej2-inputs/styles/material.css';  
@import '~@syncfusion/ej2-navigations/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-splitbuttons/styles/material.css';
@import "~@syncfusion/ej2-react-grids/styles/material.css";
@import "~react-bootstrap-sidebar-menu/dist/sidebar-menu.scss";

.main-wrapper {
  width: 100%;
  height: 100%;
  // background: #f3f4f6;
  position: absolute;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "sidebar header"
    "sidebar main";
}

.main-header {
  grid-area: header;
  padding: 0.5rem;
}

.main-container {
  grid-area: main;
  box-shadow: inset 2px 1px 9px 1px  rgba($dark, 0.10);
  overflow: auto;
  padding-top: 1rem;
}

.sidebar-menu {
  padding-top: 0;
  flex-direction: column;
  grid-area: sidebar;
  overflow-y: auto;
}


.canvasjs-chart-credit {
    display: none !important;
}

.table-responsive{
    max-height: 500px;
    overflow: auto;
}

thead.sticky_header {
    position: sticky;
    top: 0;
}


.e-grid .e-xlsel-right-border, .e-grid.e-lib:not(.e-rtl) .e-filterbarcell.e-fixedfreeze.e-freezerightborder, .e-grid:not(.e-rtl) .e-filterbarcell.e-fixedfreeze.e-freezerightborder, .e-grid.e-lib:not(.e-rtl) .e-fixedfreeze.e-freezerightborder:not(.e-removefreezerightborder), .e-grid:not(.e-rtl) .e-fixedfreeze.e-freezerightborder:not(.e-removefreezerightborder), .e-grid.e-lib .e-leftfreeze.e-freezeleftborder, .e-grid .e-leftfreeze.e-freezeleftborder, .e-grid.e-rtl .e-rightfreeze.e-freezerightborder, .e-grid.e-rtl .e-filterbarcell.e-fixedfreeze.e-freezeleftborder, .e-grid.e-rtl .e-fixedfreeze.e-freezeleftborder:not(.e-removefreezerightborder) {
    border-right-color: #bdbdbd
}

/*.e-responsive {
    max-height: 500px;
    overflow: auto;
}*/

.disablegrid {
  pointer-events: none;
  opacity: 0.5;
}


.nav-pills .nav-link.active, .nav-pills .show>.nav-link, .sidebar-menu-nav .active.sidebar-menu-nav-link, .sidebar-menu-nav .active.sidebar-menu-sub-toggle, .sidebar-menu-nav .nav-link.active, .sidebar-menu-nav .nav-pills .show>.sidebar-menu-nav-link, .sidebar-menu-nav .nav-pills .show>.sidebar-menu-sub-toggle, .sidebar-menu-nav .show>.nav-link, .sidebar-menu-nav .show>.sidebar-menu-nav-link, .sidebar-menu-nav .show>.sidebar-menu-sub-toggle {
    background-color: #0d6efd;
    border-radius: 10px;
    color: #fff;
}